/* eslint-disable */
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  forwardRef,
  useContext,
} from "react"
import { Link } from "gatsby"

import AdventureAssurance from "../../assets/AdventureAssuranceV2"
import ShippingIcon from "../../assets/ShippingIconV2"
// import AffirmIcon from "../../assets/Affirm"
// import DownloadIcon from "../../assets/Download"
import AddToCart from "../AddToCart/v2"
import NumericInput from "../NumericInput/v2"
import SelectOption from "./SelectOption"
import YotpoStarRatings from "../YotpoStarRatings"
import { downloadPdf } from "../../utils/downloadFile"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import DynamicPopUp from "../DynamicPopup"
import { StoreContext } from "../../context/StoreContext"
import "./style.css"

type Props = {
  title?: string
  affirmVariantPrice: any
  description?: string
  newVariantLoader?: boolean
  sku?: string
  storefrontId?: string
  handleClick: any
  single: any
  newSingle: boolean
  id?: string
  product: any
  productShopifyId: string
  variants: any
  hasVariants: boolean
  price: string
  lineItem: {
    variantId: string
    quantity: number
  }[]
  categoryName: string
  variant: any
  setQuantity: React.Dispatch<React.SetStateAction<number>>
  quantity: number
  optionsLibrary: any
  preorder: any
  available: boolean
  metafields: any
  preorderPopup: any
  setPreorderPopup: any
  innerDivRef: any
  showAlmostOutMessage: boolean
  popupContent: any
  setDropDownOption: (value: boolean) => void

  handleOptionChange: (index: any, event: any) => void
  availableSubOptions: any
  notice: any
  onScroll: any
  selectedOptions?: any
  setSelectedOptions?: any
  options: any[]
  // selectedVariantPrice: string
  // countryCurrency: string
  // currencyCompareAt: any
  setLineItem: React.Dispatch<
    React.SetStateAction<
      {
        variantId: string
        quantity: number
      }[]
    >
  >
}

const AddToCartSidebar = forwardRef((props: Props, ref: any) => {
  let {
    title,

    affirmVariantPrice,
    variants,
    sku,
    product,
    price,
    lineItem,
    available,
    newSingle,
    preorder,
    variant,
    categoryName,
    notice,
    hasVariants,
    newVariantLoader,
    options,
    optionsLibrary,
    productShopifyId,
    availableSubOptions,
    innerDivRef,
    single,
    selectedOptions,
    setSelectedOptions,
    setLineItem,
    handleOptionChange,
    setQuantity,
    quantity,
    metafields,
    storefrontId,
    id,
    handleClick,
    preorderPopup,
    setPreorderPopup,
    onScroll,
    showAlmostOutMessage,
    popupContent,
    // selectedVariantPrice,
    // countryCurrency,
    // currencyCompareAt,
  } = props
  const { setOpenPopUpModal } = useContext(StoreContext)
  const affirmConfig = {
    public_api_key: process.env.GATSBY_AFFIRM_PUBLIC_KEY,
    script: "https://cdn1.affirm.com/js/v2/affirm.js",
  }

  const [showAffirm, setShowAffirm] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")
  const [itemPrice, setItemQtyPrice] = useState<string | number>(0)
  const [selectedTooltip, setSelectedTooltip] = useState(-1)
  const [currencysubTotal, setCurrencySubTotal] = useState<
    string | number | null
  >(null)
  const [compareSubtotal, setCompareSubtotal] = useState<
    string | number | null
  >(null)

  useEffect(() => {
    const timer = setInterval(() => {
      const affirmModalTrigger = document.querySelector(".affirm-modal-trigger")
      setShowAffirm(affirmModalTrigger !== null)
    }, 1000)

    const stopTimer = () => {
      clearInterval(timer) // Clear the interval
    }

    setTimeout(stopTimer, 30000) // Stop the interval after 60,000 milliseconds (1 minute)

    return () => {
      clearInterval(timer) // Clear the interval when the component unmounts
    }
  }, [])

  // Run Affirm Scriptss
  useEffect(() => {
    ;(function (
      l: any,
      g: any,
      m: string,
      e: string,
      a: string,
      f: string,
      b: string,
    ) {
      var d: any,
        c = l[m] || {},
        h: HTMLScriptElement = document.createElement(f) as any,
        n = document.getElementsByTagName(f)[0],
        k = function (a: any, b: string, c: string) {
          return function () {
            a[b]._.push([c, arguments])
          }
        }

      c[e] = k(c, e, "set")
      d = c[e]
      c[a] = {}
      c[a]._ = []
      d._ = []
      c[a][b] = k(c, a, b)

      for (
        var i = 0,
          funcs =
            "set add save post open empty reset on off trigger ready setProduct".split(
              " ",
            );
        i < funcs.length;
        i++
      )
        d[funcs[i]] = k(c, e, funcs[i])

      for (
        var j = 0, props = ["get", "token", "url", "items"];
        j < props.length;
        j++
      )
        d[props[j]] = function () {}

      h.async = true
      h.src = g[f]
      n.parentNode!.insertBefore(h, n)
      delete g[f]
      d(g)
      l[m] = c
    })(window, affirmConfig, "affirm", "checkout", "ui", "script", "ready")

    // Use your live public API Key and https://cdn1.affirm.com/js/v2/affirm.js script to point to Affirm production environment.
  }, [])

  useEffect(() => {
    const initialSelectedOptions = {}
    options.forEach(({ name, values }) => {
      if (values.length > 0) {
        //@ts-ignore
        initialSelectedOptions[name] = values[0]
      }
    })
    setSelectedOptions(initialSelectedOptions)
  }, [options])

  useEffect(() => {
    let pdfUrl =
      product?.metafields.length &&
      product?.metafields.find((val: any) => val.key === "user-guide")

    setPdfUrl(pdfUrl?.value)
  }, [product])

  const handleQtyPrice = (cost: number, productQty: number) => {
    // Increase variant price by the factor of 2 for Affirm
    // Set 10,000th decimal point as SigFig

    const adjustedVariantPriceFloat = cost * productQty
    // Update data type to int
    const adjustedVariantPriceInt = adjustedVariantPriceFloat.toFixed(2)

    setItemQtyPrice(adjustedVariantPriceInt)
  }

  useEffect(() => {
    if (variant?.price) {
      handleQtyPrice(variant?.price, quantity)
    }
  }, [variant, quantity])

  // const handleSubTotalPrice = (cost: number, productQty: number) => {
  //   const adjustedVPriceFloat = cost * productQty
  //   const adjustedPriceInt = adjustedVPriceFloat.toFixed(2)
  //   setCurrencySubTotal(adjustedPriceInt)
  // }

  // useEffect(() => {
  //   if (selectedVariantPrice) {
  //     handleSubTotalPrice(parseFloat(selectedVariantPrice), quantity)
  //   }
  // }, [selectedVariantPrice, quantity])

  // const handleSubTotalCompareAtPrice = (cost: number, productQty: number) => {
  //   const adjustedCompareAtTotal = cost * productQty
  //   const adjustedCompareAtInt = adjustedCompareAtTotal.toFixed(2)
  //   setCompareSubtotal(adjustedCompareAtInt)
  // }

  // useEffect(() => {
  //   if (selectedVariantPrice) {
  //     handleSubTotalCompareAtPrice(parseFloat(selectedVariantPrice), quantity)
  //   }
  // }, [selectedVariantPrice, quantity])

  const includedList = useMemo(() => {
    try {
      return (
        JSON.parse(
          variant?.metafields?.filter(
            (field: any) => field.key === "included_list",
          )?.[0]?.value,
        ) || []
      )
    } catch (err) {
      return []
    }
  }, [variant])

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener("scroll", onScroll)
    }

    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener("scroll", onScroll)
      }
    }
  }, [ref, onScroll])

  const downloadPdfFile = async () => {
    try {
      downloadPdf(pdfUrl)
    } catch (e) {
      console.log("ERROR", e)
    }
  }

  const VariantIncludedList = useMemo(() => {
    try {
      return (
        JSON.parse(
          variant?.metafields?.filter(
            (field: any) => field.key === "included_list",
          )?.[0]?.value,
        ) || []
      )
    } catch (err) {
      return []
    }
  }, [variant])

  const ProductIncludedList = useMemo(() => {
    try {
      return (
        JSON.parse(
          metafields?.filter((field: any) => field.key === "included_list")?.[0]
            ?.value,
        ) || []
      )
    } catch (err) {
      return []
    }
  }, [variant])

  const beautifulVariantTitle = useMemo(() => {
    if (variant?.title === "Default Title") {
      return ""
    }
    const splitTitle = variant?.title?.split("/")
    if (splitTitle?.length === 1) {
      return `(${splitTitle?.[0]})`
    }
    const firstPart = splitTitle?.slice?.(0, -1)?.join("/")
    const lastPart = splitTitle?.slice?.(-1)
    if (firstPart && lastPart) {
      return `(${firstPart}${
        variant?.title?.charAt(5) === "/" ? "/" : "-"
      }${lastPart})`
    }
    return ""
  }, [variant])

  const lowestVariantPrice = useMemo(() => {
    let lowestPrice = 0
    variants.forEach((variant: any) => {
      if (variant.price < lowestPrice || lowestPrice === 0) {
        lowestPrice = variant.price
      }
    })
    return lowestPrice
  }, [variants])

  return (
    <div
      ref={ref}
      className={`bg-white lg:min-h-[calc(40vh-140px)] sm:px-[40px] p-7 lg:sticky lg:top-0 lg:right-0 lg:border lg:border-[#E5E5E5] lg:max-w-sm lg:min-w-[384px] w-full max-h-none lg:max-h-[100vh] overflow-x-hidden overflow-y-auto scroll-smooth`}
    >
      <h1 className="font-sans font-black text-2xl mb-0">
        {title}&nbsp;
        <br />
        <span className="text-sm">
          {beautifulVariantTitle?.length ? beautifulVariantTitle : ""}
        </span>
      </h1>

      {sku && (
        <p className="mb-4 mt-2 text-[#CFCFCF] font-sans font-normal text-[10px] leading-[10px]">
          SKU: {sku}
        </p>
      )}
      {productShopifyId && (
        <div onClick={() => handleClick()}>
          {/* @ts-ignore */}
          <YotpoStarRatings
            product={product}
            productShopifyId={productShopifyId}
          />
        </div>
      )}

      <div className="overflow-x-auto md:overflow-x-visible scrollbar-hidden mt-5">
        <div className="flex flex-col space-y-5">
          {hasVariants &&
            options.map(({ id, name, values }, index) => {
              const lowerCaseName = name.replace(/:/g, "").toLowerCase()

              const isGridOption = true

              const matchingOption = options.find(
                option =>
                  option.name.replace(/:/g, "").toLowerCase() === lowerCaseName,
              )

              const valueMap = matchingOption ? matchingOption.values : []
              const optionName =
                lowerCaseName === "filter type" || lowerCaseName === "size"
              const availableSubOptionsForSelected = Object.keys(
                availableSubOptions,
              ).length
                ? availableSubOptions
                : { [name]: values }

              const availableSubOptionsForFilter =
                availableSubOptionsForSelected[name]
              const filteredValues = optionName
                ? values
                : availableSubOptionsForFilter || values

              const removeDuplicates = (arr: any[]) => [...new Set(arr)]
              const uniqueFilteredValues = removeDuplicates(filteredValues)

              const selectedOption = variant?.selectedOptions?.find(
                (i: any) => i.name === name,
              )?.value

              return (
                <div className="flex flex-col space-y-2" key={id}>
                  {isGridOption ? (
                    <>
                      <p className="font-bold text-xs m-0">
                        {name?.endsWith(":") ? name : `${name}:`}
                      </p>
                      <div className="flex overflow-x-auto md:overflow-x-visible md:flex-wrap gap-2 scrollbar-hidden">
                        {uniqueFilteredValues.map(
                          (value: any, valueIndex: number) => {
                            const valueExists =
                              valueMap?.length && valueMap[valueIndex]
                            if (!valueExists && !optionName) {
                              return null
                            }
                            const isSelected = selectedOption === value

                            return (
                              <div
                                key={`${name}-${value}`}
                                className={`flex items-center !m-0 pt-6 h-8 justify-center px-2 rounded-2xl border transition-colors duration-200 cursor-pointer
                          ${optionName ? "font-bold" : ""}
                          ${
                            isSelected
                              ? "border border-paloverde"
                              : "border border-gray-400 hover:border-paloverde"
                          }`}
                                data-value={value}
                                data-type={name}
                                onClick={() => {
                                  handleOptionChange(index, {
                                    target: {
                                      value: value,
                                      name,
                                    },
                                  })
                                }}
                                role="button"
                                tabIndex={0}
                                aria-label={`${name}: ${value}`}
                              >
                                <p
                                  className={`${
                                    value?.length > 1
                                      ? "flex justify-center text-xs font-normal text-center whitespace-nowrap"
                                      : ""
                                  }`}
                                >
                                  {value}
                                </p>
                              </div>
                            )
                          },
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center">
                      <SelectOption
                        selectedVariant={variant}
                        handleOptionChange={handleOptionChange}
                        id={id}
                        index={index}
                        name={name}
                        values={
                          name === optionsLibrary.name
                            ? values
                            : availableSubOptions[name]
                        }
                      />
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </div>

      <div className="mt-5">
        {!!ProductIncludedList?.length && !VariantIncludedList?.length ? (
          <div>
            <p className="font-medium text-[#1C1C1C] text-sm mb-2">Includes:</p>
            <div className="mb-2">
              <div className="flex flex-col font-bold   ">
                {ProductIncludedList?.map((item: string) => (
                  <div className="flex items-center gap-2 space-y-2 relative">
                    <div className="border-[1px] p-[1px] list-disc list-inside border-black w-2 h-2 rounded-full mt-[7px]">
                      <div className="bg-black w-full h-full  rounded-full">
                        {" "}
                      </div>
                    </div>
                    <div className="text-[15px] leading-4">
                      {item ? item : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {!!popupContent?.length && (
          <div className="flex mt-4 ml-3">
            <div className="clothing-pop-up-links-wrapper space-x-2 !m-0">
              {popupContent?.map((item: any, index: any) => {
                const areMoreThanOneAndNotLast =
                  index < popupContent.length - 1 && popupContent.length !== 1
                return (
                  <>
                    <a
                      onClick={e => {
                        e.preventDefault()
                        setOpenPopUpModal(true)
                        setSelectedTooltip(index)
                      }}
                    >
                      <p className="clothing-pop-up-link size-and-fit">
                        {item.title}
                      </p>
                    </a>
                    {areMoreThanOneAndNotLast && <span> | </span>}
                  </>
                )
              })}
            </div>
            <DynamicPopUp
              show={selectedTooltip > -1}
              item={popupContent[selectedTooltip]}
              hide={() => {
                setOpenPopUpModal(false)
                setSelectedTooltip(-1)
              }}
            />
          </div>
        )}
        {VariantIncludedList?.length > 0 && (
          <div>
            <p className="font-medium text-[#1C1C1C] text-sm mb-2 font-bold">
              Includes:
            </p>
            <div className="mb-2">
              <div className="flex flex-col font-bold  ">
                {VariantIncludedList?.map((item: string) => (
                  <div className="flex gap-2 relative">
                    <div className="border-[1px] p-[1px] border-black w-2 h-2 rounded-full mt-[7px]">
                      <div className="bg-black w-full h-full  rounded-full">
                        {" "}
                      </div>
                    </div>
                    <div className="text-[15px]">{item ? item : ""}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full items-center gap-2">
        <div className="flex affirm-as-low-as">
          <NumericInput
            aria-label="Quantity"
            onIncrement={() => setQuantity(q => Math.min(q + 1, 20))}
            onDecrement={() => setQuantity(q => Math.max(1, q - 1))}
            onChange={event =>
              setQuantity(parseInt(event.currentTarget.value, 10))
            }
            value={quantity as unknown as string}
            min="1"
            max="20"
          />
        </div>
        {showAlmostOutMessage && (
          <p className="m-0 p-0 text-[#1c1c1c] text-[10px] font-bold self-end">
            Almost Out. This item is low in stock
          </p>
        )}
      </div>
      <div className="border-t border-[#E5E5E5] mt-5 pt-7">
        <div>
          <>
            {price === "$NaN" ? (
              <>
                <p className="mb-0 font-medium text-[#1C1C1C] text-sm">
                  Price:
                </p>
                <p className="text-[#1C1C1C] font-sans font-bold text-lg mb-0 mt-1.5">
                  {/* {selectedVariantPrice ? selectedVariantPrice : `$${itemPrice}`} */}
                  {`$${itemPrice}`}
                </p>
              </>
            ) : (
              <>
                <p className="font-medium text-[#1C1C1C] text-sm mb-2 font-bold">
                  Price:{" "}
                </p>
                <h4>
                  {!variant?.compareAtPrice ? (
                    <span className="text-[#1C1C1C] font-sans font-bold text-lg mb-0 mt-1.5">
                      {/* {selectedVariantPrice ? (
                      <>
                        {countryCurrency === "USD"
                          ? "$"
                          : countryCurrency + " "}
                        {currencysubTotal}
                      </>
                    ) : (
                      `$${itemPrice}`
                    )} */}

                      {`$${itemPrice}`}
                    </span>
                  ) : (
                    <>
                      <span
                        id="priceId"
                        className={`mb-0 text-lg text-[#454545]`}
                      >
                        {/* {selectedVariantPrice ? (
                        <>
                          {countryCurrency === "USD"
                            ? "$"
                            : countryCurrency + " "}
                          {compareSubtotal}
                        </>
                      ) : (
                        `$${itemPrice}`
                      )} */}
                        {`$${itemPrice}`}
                      </span>
                      <span
                        className={`ml-2 ${
                          variant?.compareAtPrice && "line-through"
                        } text-[#1C1C1C] font-sans font-bold text-lg mb-0 mt-1.5`}
                      >
                        {/* {currencyCompareAt ? (
                        <>
                          {" "}
                          {countryCurrency === "USD"
                            ? "$"
                            : countryCurrency + " "}
                          {currencyCompareAt}
                        </>
                      ) : (
                        `$${variant?.compareAtPrice}`
                      )} */}
                        {`$${variant?.compareAtPrice}`}
                      </span>
                    </>
                  )}
                </h4>
              </>
            )}
          </>
          <div className="mt-5">
            <AddToCart
              productLists={lineItem}
              newVariantLoader={newVariantLoader}
              setProductLists={setLineItem}
              available={available}
              preorder={preorder.preorder}
              preorderDate={preorder.preorderDate}
              price={price}
              productName={product.title}
              variantName={variant?.title}
              variant={variant}
              categoryName={categoryName}
              hasVariants={hasVariants}
              storefrontId={storefrontId}
              quantity={quantity}
              id={id}
              single={single}
              variants={variants}
              newSingle={newSingle}
              preorderPopup={preorderPopup}
              setPreorderPopup={setPreorderPopup}
              innerDivRef={innerDivRef}
            />
          </div>
          {newVariantLoader ? (
            <div className="mt-5">
              <Skeleton count={3} />
            </div>
          ) : (
            <div className={`flex flex-col mt-6`}>
              <div className="w-full flex items-start text-[#1c1c1c]">
                <div className="mr-2.5">
                  <ShippingIcon />
                </div>

                <div className="text-xs font-condensed font-bold text-[#1c1c1c]">
                  {notice}
                </div>
              </div>
              <div className="w-full flex items-start mt-5">
                <div className="mr-2.5">
                  <AdventureAssurance />
                </div>
                <div className="text-xs font-condensed font-bold text-[#1c1c1c]">
                  <h1 className="mb-0 text-xs text-[#1c1c1c]">
                    AdventureAssurance™ Backed
                  </h1>
                  <p className="mb-0 text-[11.5px] mt-1.5 text-[#1c1c1c] font-normal font-sans">
                    Lifetime Warranty against Manufacture Defects money back.{" "}
                    <a
                      className="underline italic"
                      href="https://www.polarpro.com/support/warranty"
                      target="_blank"
                    >
                      Learn More.
                    </a>
                  </p>
                </div>
              </div>
              {/* {
                affirmVariantPrice >= 5000 && (
                  // (!countryCurrency?.length || countryCurrency === "USD") ? (
                  <div className="w-full flex items-start mt-3">
                    <div className="mr-2.5">
                      <AffirmIcon />
                    </div>
                    <div
                      className="affirm-as-low-as text-xs font-condensed font-bold text-[#1c1c1c]"
                      data-page-type="product"
                      data-amount={affirmVariantPrice}
                      data-sku={sku}
                    >
                      <span
                        className="affirm-as-low-as mb-0 text-[12px] text-[#1c1c1c] affirm-as-low-as mb-1 h-[21px]"
                        data-amount={affirmVariantPrice}
                      />
                    </div>
                  </div>
                )
                // ) : null
              } */}
              <div className="w-full items-start mt-5 "></div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default AddToCartSidebar
