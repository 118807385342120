/* eslint-disable */
import { getSrc } from "gatsby-plugin-image"
import React, { useContext, useEffect, useMemo, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Carousel from "react-multi-carousel"
import axios from "axios"
import { isEqual } from "lodash"
import { useLocation } from "@reach/router"
import { render } from "storyblok-rich-text-react-renderer"
import { Link } from "gatsby"
import { sbEditable } from "@storyblok/storyblok-editable"
import AddToCartSidebar from "../components/DefaultGenericProductv2/AddToCartSidebar.tsx"
import MobileScrollAddCart from "../components/DefaultGenericProductv2/MobileScrollAddCart.tsx"
import "./style.scss"
import * as style from "./style.module.scss"
import "react-multi-carousel/lib/styles.css"
import Seo from "../components/seo"
import { useStoryblok } from "../utils/storyblok"
import "./ProductContentStyling.css"
import collectionHandles from "../utils/collectionHandles"
import { StoreContext } from "../context/StoreContext"
import useProduct from "../hooks/useProduct"
import { formatPrice } from "../utils/formatPrice"
import shipmentNoticeSetter from "../utils/shipmentNoticeHelper"
import FeaturedProductImagesCarousel from "../components/FeaturedProductImagesCarousel/v2.jsx"
import ProductRecommendationv2 from "../components/ProductRecommendationv2"
import RatingsAndComments from "../components/ProductRecommendationv2/RatingsAndComments"
import DynamicComparison from "../components/DynamicComparison"
import DynamicComponents from "../components/DynamicComponents"
import ProductBottomSection from "../components/DefaultGenericProductv2/ProductBottomSection.jsx"
import { NewUserContext } from "../context/NewUserContext"
import GenericTemplateBottom from "../components/GenericTemplateBottom/index.js"
import { WidgetScript } from "../scripts/widget"
import DynamicComponentsV2 from "../components/DynamicComponentsV2/index.tsx"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
// import { useCurrencyExchange } from "../context/CurrencyExchange"
import { productEndPointUrl } from "../config"

const RetentionViewProduct = ({
  productId,
  product,
  location,
  orderId,
  totalPrice,
  email,
}) => {
  const geqScriptRef = useRef(null)

  const trackOrderScriptRef = useRef(null)

  useEffect(() => {
    // if (!geqScriptRef.current) {
    const geqScript = document.createElement("script")
    geqScript.type = "text/javascript"
    geqScript.async = true

    const item = {
      productId,
      name: product?.title,
      url: location?.url,
    }

    geqScript.innerHTML = `
        window.geq = window.geq || [];
        geq.event("Viewed Product Reclaim", ${JSON.stringify(item)});
      `

    geqScriptRef.current = geqScript
    document.head.appendChild(geqScript)
    // }
  }, [productId, product, location])

  useEffect(() => {
    // if (!trackOrderScriptRef.current) {
    //   const trackOrderScript = document.createElement("script")
    //   trackOrderScript.type = "text/javascript"
    //   trackOrderScript.innerHTML = `
    //     window.geq = window.geq || [];
    //     geq.trackOrder({ order_number: "${orderId}", order_amount: "${totalPrice}", order_email: "${email}" });
    //   `
    //   trackOrderScriptRef.current = trackOrderScript
    //   document.head.appendChild(trackOrderScript)
    // }
  }, [orderId, totalPrice, email])

  return null
}

const DefaultGenericProductv2 = props => {
  const { pageContext } = props
  const {
    product,
    recommendedProducts,
    productWhiteList,
    productPageLinks,
    helixProducts,
    missingProductOne,
    missingProductTwo,
    missingProductThree,
    dynamicATCProducts,
    shipmentnoticemessagedata,
  } = pageContext

  const story = pageContext.storyblokEntry[0]?.node
    ? useStoryblok(pageContext.storyblokEntry[0]?.node)
    : { content: null }
  const whiteList = productWhiteList?.data
    ? useStoryblok(productWhiteList?.data?.storyblokEntry)
    : { content: null }
  const array = story?.content?.components
  const carouselRef = React.useRef()

  const oneWholeMetaField = product?.metafields.filter(
    item => item.key == "one-whole",
  )

  const {
    media: images,
    variants: [initialVariant],
    variants,
    shopifyId,
    priceRangeV2,
    options,
    collections,
    metafields,
    storefrontId,
    id,
  } = product

  const { client } = useContext(StoreContext)
  const { cartOverlay, setCartOverlay } = useContext(NewUserContext)
  const { setContextRecommendedProducts } = useContext(StoreContext)
  const location = useLocation()
  const [variant, setVariant] = useState({})
  const [isCustomOverview, setIsCustomOverview] = useState(false)
  const isHelix = location.pathname.includes("helix-mckinnon")
  const isMavic3 =
    location.pathname.includes("mavic3-pro") ||
    location.pathname.includes("mavic-3-pro")
  const myDivRef = useRef(null)

  const handleClick = () => {
    window.location.href = "#ratingSection"
  }

  const [lineItem, setLineItem] = useState([
    {
      variantId: "",
      quantity: 1,
    },
  ])
  const [imageIndex, setImageIndex] = useState(0)
  const [runVariantFilter, setRunVariantFilter] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [collectionIndex, setCollectionIndex] = useState(0)
  const [optionsLibrary, setOptionsLibrary] = useState({})
  const [availableSubOptions, setAvailableSubOptions] = useState([])
  const [imageEnumeration, setImageEnumeration] = useState({})
  const [shippingHours, setShippingHours] = useState("Now")
  const [displayBottom, setDisplayBottom] = useState(false)
  const [mouseRightHover, setMouseRightHover] = useState(false)
  const [affirmVariantPrice, setaffirmVariantPrice] = useState(0)
  const { available, preorder, title, description, descriptionHtml } =
    useProduct({ variant, client, product })
  const shopifyIdExtractor = id => id?.slice(id.lastIndexOf("/") + 1)

  const affirmPriceSetter = cost => {
    if (cost) {
      const parsedPrice = Number.parseInt((cost * 100).toFixed(4), 10)
      setaffirmVariantPrice(parsedPrice)
    }
  }

  const privateAxios = useAxiosPrivate()
  const [apiSelectedVariant, setAPISelectedVariant] = useState(null)
  useEffect(() => {
    affirmPriceSetter(variant.price)
  }, [variant.price])
  useEffect(() => {
    setContextRecommendedProducts(
      recommendedProducts.filter(product => product),
    )
  }, [recommendedProducts])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1025 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
  }
  useEffect(() => {
    setLineItem([{ ...lineItem[0], quantity }])
  }, [quantity])

  let selectedVariant

  const canonicalUrl = `https://www.polarpro.com${location.pathname}`

  const productId = shopifyIdExtractor(shopifyId)
  // const { getCountryCode } = useCurrencyExchange()
  // const { userCountry } = useCurrencyExchange()
  // const [currencies, setCurrencies] = useState([])
  // const [selectedVariantPrice, setSelectedVariantPrice] = useState(0)
  // const [countryCurrency, setCountryCurrency] = useState("")
  // const [currencyCompareAt, setCurrencyCompareAt] = useState(0)

  const [selectedOptions, setSelectedOptions] = useState({})

  const handleOptionChange = (index, event) => {
    const { value } = event.target

    if (value === "") {
      return
    }

    setSelectedOptions(prevSelectedOptions => ({
      ...prevSelectedOptions,
      [event.target.name]: value,
    }))

    // If primary option is selected, show the appropriately available sub-options
    if (optionsLibrary.root.includes(value)) {
      setAvailableSubOptions(optionsLibrary[value])
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    /* eslint-disable-next-line max-len */
    let selectedVariant = variants.find(variantItem => {
      return isEqual(currentOptions, variantItem.selectedOptions)
    })

    if (!selectedVariant) {
      const firstMatchOfFirstIndex = variants.find(variantItem => {
        return currentOptions[0].value === variantItem.selectedOptions[0].value
      })
      selectedVariant = firstMatchOfFirstIndex
    }
    const removeValueAfterPng = value => {
      const index = value.indexOf(".png")
      return index !== -1 ? value.substring(0, index + 4) : value
    }
    if (selectedVariant?.image) {
      const fieldToChoose =
        selectedVariant?.image?.src ||
        selectedVariant?.image?.altText ||
        selectedVariant?.image?.originalSrc ||
        selectedVariant?.image?.id ||
        selectedVariant?.image?.src ||
        ""
      const fieldWithoutExtension = removeValueAfterPng(fieldToChoose)
      const selectedShopifyImageId = fieldWithoutExtension || undefined
      if (selectedShopifyImageId) {
        if (
          imageEnumeration[
            removeValueAfterPng(fieldToChoose.split(".webp")?.[0])
          ] !== undefined
        ) {
          setImageIndex(
            imageEnumeration[
              removeValueAfterPng(fieldToChoose.split(".webp")?.[0])
            ],
          )
        } else {
          for (const key of Object.keys(imageEnumeration)) {
            const keyWithoutExtension = removeValueAfterPng(key)
            const searchStringWithoutExtension = removeValueAfterPng(
              fieldWithoutExtension,
            )
            if (keyWithoutExtension === searchStringWithoutExtension) {
              const value = imageEnumeration[key]
              setImageIndex(value)
              break
            }
          }
        }
      }
    }

    setRunVariantFilter(true)

    if (selectedVariant) {
      const newTitle = selectedVariant.title
      decodeURIComponent(newTitle.replace(/ - /g, "-")).trim()
      function sanitizeString(input) {
        let sanitized = ""
        for (let i = 0; i < input.length; i++) {
          const char = input[i]
          if (
            (char >= "a" && char <= "z") ||
            (char >= "A" && char <= "Z") ||
            (char >= "0" && char <= "9") ||
            char === "-"
          ) {
            sanitized += char
          }
        }
        return sanitized
      }
      const variantUrl = new URLSearchParams(window.location.search)
      variantUrl.set("variant", sanitizeString(newTitle))
      window.history.pushState(
        {},
        null,
        `${window.location.pathname}?${variantUrl.toString()}`,
      )
    }
    setVariant({ ...selectedVariant })
    // const variantShopifyId = variant?.shopifyId
    // const selectedProductId =
    //   currencies?.product?.data?.product?.variants?.edges?.find(
    //     item => item?.node?.id === variantShopifyId,
    //   )
    // if (selectedProductId) {
    //   const selectedVariantPrice = selectedProductId.node.price.amount
    //   setSelectedVariantPrice(selectedVariantPrice)
    //   setCurrencyCompareAt(selectedProductId?.node?.compareAtPrice?.amount)
    //   setCountryCurrency(selectedProductId.node.price.currencyCode)
    // } else {
    //   return null
    // }
  }

  // const getproductCurrency = async () => {
  //   try {
  //     const response = await axios.post(productEndPointUrl, {
  //       country: userCountry?.cca2,
  //       productId: shopifyId,
  //     })
  //     if (response.data !== undefined && response.data !== null) {
  //       setCurrencies(response.data)
  //     }
  //   } catch (err) {
  //     return {
  //       statusCode: 500,
  //       body: JSON.stringify({
  //         failed: true,
  //         message: err.message,
  //       }),
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (userCountry?.cca2 !== undefined) {
  //     const fetchData = async () => {
  //       if (currencies.length === 0) {
  //         await getproductCurrency()
  //       }
  //       if (currencies?.product?.data?.product?.variants?.edges?.length > 0) {
  //         const selectedProductId =
  //           currencies.product.data.product.variants.edges.find(
  //             item => item?.node?.id === variant?.shopifyId,
  //           )

  //         if (selectedProductId) {
  //           const selectedVariantPrice = selectedProductId.node.price.amount
  //           setSelectedVariantPrice(selectedVariantPrice)
  //           setCountryCurrency(selectedProductId.node.price.currencyCode)
  //           setCurrencyCompareAt(
  //             selectedProductId?.node?.compareAtPrice?.amount,
  //           )
  //         } else {
  //           return null
  //         }
  //       }
  //     }

  //     fetchData()
  //   }
  // }, [currencies, variant, userCountry])

  // useEffect(() => {
  //   if (userCountry?.cca2 !== undefined) {
  //     const userCountry = getCountryCode(shopifyId)
  //     getproductCurrency(userCountry)
  //   }
  // }, [shopifyId, userCountry, variant])

  const [reviewsData, setReviewsData] = useState()
  const [questionsData, setQuestionsData] = useState()

  const getReviews = async () => {
    await axios
      .post("/.netlify/functions/get-reviews", {
        product_id: productId,
      })
      .then(async res => {
        setReviewsData(res?.data?.response)
      })
      .catch(err => {
        console.error(err, "error")
      })
  }

  const getQuestions = async () => {
    await axios
      .post("/.netlify/functions/get-questions", {
        product_id: productId,
      })
      .then(async res => {
        setQuestionsData(res?.data?.response)
      })
      .catch(err => {
        console.error(err, "<=error")
      })
  }

  const productRef = useRef(null)
  const cartRef = useRef(null)

  const [showDiv, setShowDiv] = useState(false)
  useEffect(() => {
    if (
      window?.location?.pathname === "/products/mavic3-pro-polarizer-filter/"
    ) {
      localStorage.setItem("breadcrumb", "mavi3-pro")
    }

    const handleScroll = () => {
      const isNearBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
      if (isNearBottom) {
        setShowDiv(false)
      } else if (window.scrollY > 1500 && !showDiv) {
        // setShowDiv(true)
      } else if (window.scrollY <= 1500 && showDiv) {
        setShowDiv(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [showDiv])

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false
    const isDown = true

    const updateScrollDir = () => {
      if (window.location.hash === "#ratingSection") {
        return
      }
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }

      if (cartRef.current && productRef.current && scrollY > lastScrollY) {
        cartRef.current.scrollTop = window.scrollY // Sync cartRef scrolling with window scrolling
      }
      // TO REMOVE RIGHT SCROLLING JURK
      if (cartRef.current && productRef.current && !mouseRightHover) {
        // scrollY > lastScrollY
        cartRef.current.scrollTop = window.scrollY // Sync cartRef scrolling with window scrolling
      }

      // HANDLE RIGHT SIDE SCROLL_UP FROM LEFT SIDE SCROLLUP
      if (scrollY < lastScrollY) {
        cartRef.current.scrollTop = productRef.current.scrollTop
      }

      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [mouseRightHover])

  const shipmentMessageStory = shipmentnoticemessagedata?.data?.storyblokEntry
    ? useStoryblok(shipmentnoticemessagedata?.data?.storyblokEntry)
    : { content: null }
  const offsetDays = shipmentMessageStory?.content?.offset_days || 2

  useEffect(() => {
    const updateShippingHours = () => {
      shipmentNoticeSetter(setShippingHours, offsetDays)
    }
    // Call shipmentNoticeSetter immediately to update the state
    shipmentNoticeSetter(setShippingHours, offsetDays)
    // Start the recurring updates with setTimeout
    setTimeout(updateShippingHours, 5000)

    return () => {
      clearTimeout()
    }
  }, [selectedVariant])

  const [isInitialRender, setIsInitialRender] = useState(true)

  useEffect(() => {
    setIsInitialRender(true)
    getReviews()
    getQuestions()
    if (collections?.length > 1) {
      for (let i = 0; i <= collections?.length; i++) {
        // eslint-disable-line no-plusplus

        if (isMavic3) {
          let availableIndex = 4
          if (collections?.[4]) {
            availableIndex = 4
          } else if (collections?.[3]) {
            availableIndex = 3
          } else if (collections?.[2]) {
            availableIndex = 2
          } else if (collections?.[1]) {
            availableIndex = 1
          } else {
            availableIndex = 0
          }
          setCollectionIndex(availableIndex)
        } else if (collectionHandles.has(collections?.[i]?.handle)) {
          setCollectionIndex(i)
          break
        }
      }
    }

    const tempImageEnumeration = {}
    images.forEach((image, index) => {
      const imageShopifyId = shopifyIdExtractor(image.id)

      const fieldToChoose =
        image?.image?.src ||
        image?.image?.altText ||
        image?.image?.originalSrc ||
        imageShopifyId
      // split the webp because it was generating randon num on both create key here and match key in bottom
      tempImageEnumeration[fieldToChoose.split(".webp")[0]] = index
    })

    setImageEnumeration(tempImageEnumeration)

    // Primary option object keeps track of the first options selector name
    const primaryOption = {}
    // Reorganize data will live in optionLibrary
    const optionLibrary = {}
    const { name, values } = options[0]

    // Set primary option data with the first option data
    primaryOption.name = name
    primaryOption.values = values

    // Keep track of the primary option data in optionLibrary object
    optionLibrary.name = name
    optionLibrary.root = values

    // Use primary options values as the key for storing subsequent options
    values.forEach(value => {
      optionLibrary[value] = {}
    })

    variants.forEach(({ selectedOptions }) => {
      let primaryOptionName = ""
      selectedOptions.forEach(selectedOption => {
        // Check if the vairant has primary option. If it does, use it's value as key
        if (selectedOption.name === optionLibrary.name) {
          primaryOptionName = selectedOption.value
        } else if (optionLibrary[primaryOptionName][selectedOption.name]) {
          // Add available sub options for each key
          // eslint-disable-next-line max-len
          optionLibrary[primaryOptionName][selectedOption.name] = [
            ...optionLibrary[primaryOptionName][selectedOption.name],
            selectedOption.value,
          ]
        } else {
          // If the key does not exist, create new list of sub-options array
          optionLibrary[primaryOptionName][selectedOption.name] = [
            selectedOption.value,
          ]
        }
      })
    })

    // Set optionsLibrary state
    setOptionsLibrary(optionLibrary)

    // Set up the first available sub-options
    initialVariant?.selectedOptions?.forEach(selectedOption => {
      if (selectedOption.name === optionLibrary.name) {
        setAvailableSubOptions(optionLibrary[selectedOption.value])
      }
    })

    const script = document.createElement("script")
    script.src =
      "https://staticw2.yotpo.com/qNmEg7HN0QiYfqn6n5DHRUGCtTUGNWa1GfSVsHRS/widget.js"
    script.async = true
    document.head.appendChild(script)

    script.onload = e => {
      // @ts-ignore
      window.yotpo.refreshWidgets()
    }
    setIsInitialRender(false)

    const fetchData = async () => {
      try {
        const data = {
          product_id: shopifyIdExtractor(shopifyId),
          id: shopifyId,
        }
        const result = await privateAxios.post(
          "/.netlify/functions/inventory-update",
          data,
        )
        setAPIData(result?.data?.variants || [])
      } catch (error) {
        setAPIData([])
      }
    }
    fetchData()
    return () => {
      setIsInitialRender(true)
      script.remove()
    }
  }, [])

  const popupContent = useMemo(() => story?.content?.tooltips || [], [story])

  const productShopifyId = useMemo(
    () => shopifyIdExtractor(shopifyId),
    [shopifyId],
  )

  const price = useMemo(() => {
    if (variants.length == 1) {
      return formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        variants[0].price,
      )
    }
    return formatPrice(priceRangeV2.minVariantPrice.currencyCode, variant.price)
  }, [priceRangeV2, variant])

  const cleanHelixProducts = useMemo(() => {
    if (!isHelix) return []
    return helixProducts.map(product => {
      const [variant] = product.node.variants
      return {
        ...product.node,
        ...variant,
        title: product.node.title.split("Helix | ").join(""),
        isProduct: true,
      }
    })
  }, [helixProducts, location])

  const cleanRTCProducts = useMemo(() => {
    if (!dynamicATCProducts) return []
    return dynamicATCProducts.map(product => {
      const [variant] = product.variants || []
      return {
        ...(product || {}),
        ...(variant || {}),
        isProduct: true,
      }
    })
  }, [dynamicATCProducts, location])

  const cleanMissingProductOne = useMemo(
    () =>
      missingProductOne?.map(product => {
        const [variant] = product.node.variants
        return {
          ...product.node,
          ...variant,
          title: product.node.title.split("Helix | ").join(""),
          isProduct: true,
        }
      }),
    [missingProductOne, location],
  )

  const cleanMissingProductTwo = useMemo(
    () =>
      missingProductTwo.map(product => {
        const [variant] = product.node.variants
        return {
          ...product.node,
          ...variant,
          title: product.node.title.split("Helix | ").join(""),
          isProduct: true,
        }
      }),
    [missingProductOne, location],
  )

  const cleanMissingProductThree = useMemo(
    () =>
      missingProductThree.map(product => {
        const [variant] = product.node.variants
        return {
          ...product.node,
          ...variant,
          title: product.node.title.split("Helix | ").join(""),
          isProduct: true,
        }
      }),
    [missingProductOne, location],
  )

  const [
    BottomSectionImage1,
    BottomSectionImage2,
    explainerVideobanner,
    sections,
    multipleSectionTitle,
    comparisonMainImage,
    order,
    allSliders,
    features,
    overview,
    belowContentComponent,
  ] = useMemo(
    () => [
      story?.content?.BottomSectionImage1,
      story?.content?.BottomSectionImage2,
      story?.content?.videoLink,
      story?.content?.sections,
      story?.content?.section,
      story?.content?.comparisonMainImage?.filename,
      story?.content?.Order,
      story?.content?.AllSliders,
      render(story?.content?.features),
      render(story?.content?.overview),
      story.content && story.content.below_content ? (
        story.content.below_content.map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)}>
                <DynamicComponents
                  product={product}
                  blok={blok}
                  key={blok._uid}
                  dynamicATCProducts={dynamicATCProducts}
                />
              </div>
            ) : null}
          </>
        ))
      ) : (
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: oneWholeMetaField[0]?.value }}
        ></div>
      ),
    ],
    [story, cleanHelixProducts],
  )

  const categoryName = useMemo(() => {
    if (collections?.length) {
      return collections?.[collectionIndex]?.title
    }
    return ""
  }, [collections])

  const variantSKU = useMemo(
    () => (variant?.sku ? variant?.sku : variants[0]?.sku),
    [variant],
  )

  const hasVariants = useMemo(() => variants.length > 1, [variants])

  const notice = useMemo(() => {
    if (apiSelectedVariant) {
      if (!apiSelectedVariant?.available) {
        return <span>Out of stock.</span>
      }
      if (apiSelectedVariant?.preorder?.preorder) {
        return (
          <div>
            <span className="text-paloverde">RESERVE TODAY! </span>
            <span>
              Estimated to ship
              {` ${apiSelectedVariant?.preorder?.preorderDate}`}.
            </span>
          </div>
        )
      }
      return (
        <div>
          <span className="text-paloverde"> IN STOCK! </span>
          <span>{` ${shippingHours}`}</span>
        </div>
      )
    }
    if (selectedVariant && selectedVariant?.availableForSale == false) {
      return <span>Out of stock.</span>
    }
    if (
      selectedVariant === undefined &&
      variants[0]?.availableForSale == false
    ) {
      return <span>Out of stock.</span>
    }
    if (preorder.preorder) {
      return (
        <div>
          <span className="text-paloverde">RESERVE TODAY! </span>
          <span>
            Estimated to ship
            {` ${preorder.preorderDate}`}.
          </span>
        </div>
      )
    }
    return (
      <div>
        <span className="text-paloverde"> IN STOCK! </span>
        <span>{` ${shippingHours}`}</span>
      </div>
    )
  }, [available, preorder, shippingHours, apiSelectedVariant])

  const paginate = data => {
    setReviewsData(data)
  }
  useEffect(() => {
    const storyBlokWhiteList = whiteList?.content?.whitelist?.map(
      item => item.slug,
    )

    const value = storyBlokWhiteList?.filter(item =>
      location.pathname.includes(item),
    )
    if (value?.length > 0) {
      setDisplayBottom(true)
    }
    const whitelist = storyBlokWhiteList?.length
      ? storyBlokWhiteList
      : [
          "boreal-50l-backpack",
          "litechaser-pro-iphone-14-pro-pro-max",
          "nd-filters",
        ]

    if (sections?.length) {
      const isWhiteList = !!whitelist.filter(section =>
        location.pathname.includes(section),
      )?.length

      setIsCustomOverview(isWhiteList)
    } else {
      setIsCustomOverview(true)
    }
  }, [sections])

  // Configure public API key and script location for Affirm
  const affirmConfig = {
    public_api_key: process.env.GATSBY_AFFIRM_PUBLIC_KEY,
    script: "https://cdn1.affirm.com/js/v2/affirm.js",
  }

  // Run Affirm Script
  useEffect(() => {
    ;(function (l, g, m, e, a, f, b) {
      let d
      const c = l[m] || {}
      const h = document.createElement(f)
      const n = document.getElementsByTagName(f)[0]
      const k = function (a, b, c) {
        return function () {
          a[b]._.push([c, arguments])
        }
      }
      c[e] = k(c, e, "set")
      d = c[e]
      c[a] = {}
      c[a]._ = []
      d._ = []
      c[a][b] = k(c, a, b)
      a = 0
      for (
        b =
          "set add save post open empty reset on off trigger ready setProduct".split(
            " ",
          );
        a < b.length;
        a++
      )
        d[b[a]] = k(c, e, b[a])
      a = 0
      for (b = ["get", "token", "url", "items"]; a < b.length; a++)
        d[b[a]] = function () {}
      h.async = !0
      h.src = g[f]
      n.parentNode.insertBefore(h, n)
      delete g[f]
      d(g)
      l[m] = c
    })(window, affirmConfig, "affirm", "checkout", "ui", "script", "ready") // eslint-disable-line
    // Use your live public API Key and https://cdn1.affirm.com/js/v2/affirm.js script to point to Affirm production environment.

    try {
      snaptr("track", PAGE_VIEW, {
        item_ids: [productShopifyId],
        item_category: "title",
      })
    } catch (err) {}
  }, [])
  const stickyNavComponent =
    story.content && story.content.sticky_nav
      ? story.content.sticky_nav.map(blok => (
          <>
            {blok ? (
              <div {...sbEditable(blok)} className="relative">
                <DynamicComponents
                  blok={blok}
                  product={product}
                  key={blok._uid}
                  cleanHelixProducts={cleanHelixProducts}
                  cleanMissingProductOne={cleanMissingProductOne}
                  cleanMissingProductTwo={cleanMissingProductTwo}
                  cleanMissingProductThree={cleanMissingProductThree}
                />
              </div>
            ) : null}
          </>
        ))
      : null

  const toggleCart = () => {
    setCartOverlay(!cartOverlay)
  }

  // scrolling method
  const handleChild1Scroll = () => {
    if (window.location.hash === "#ratingSection") {
      return
    }
    if (cartRef.current) {
    }
  }

  const handleChild2Scroll = () => {
    if (productRef.current) {
      productRef.current.scrollTop = cartRef.current.scrollTop
    }
  }

  const { email, orderId, totalPrice } = useMemo(() => {
    let email
    let totalPrice
    let orderId

    if (typeof window !== "undefined" && localStorage) {
      email = localStorage.getItem("email")
      totalPrice = localStorage.getItem("totalPrice")
      orderId = localStorage.getItem("orderId")
    }

    return {
      email,
      totalPrice,
      orderId,
    }
  }, [])

  const completeUrl = location.href

  const KlaviyoScript = () => {
    const defaultImageUrl =
      "https://cdn.shopify.com/s/files/1/1050/9944/products/helix-vnd2-5-kit.webp?v=1690823095"
    useEffect(() => {
      const item = {
        ProductName: title,
        ProductID: productShopifyId,
        SKU: variantSKU,
        Categories: categoryName,
        ImageURL:
          variant?.image?.gatsbyImageData?.images?.fallback?.src ||
          variant?.image?.src ||
          product?.media?.[0]?.image?.src ||
          defaultImageUrl,
        URL: completeUrl,
        Brand: "PolarPro",
        Price: variant?.price,
        CompareAtPrice: variant?.compareAtPrice,
        preOrderTag: preorder?.preorderDate ?? null,
      }
      try {
        window?.klaviyo?.push?.(["track", "View Product", item])
        _learnq.push(["track", "Viewed Product", item])
      } catch (err) {
        console.log("pushing to klaviyo failed", err)
      }
    }, [])
    return null
  }

  useEffect(() => {
    if (!isInitialRender) {
      const params = new URLSearchParams(window.location.search)
      const searchPharm = params.get("variant")
      if (!searchPharm) {
        setVariant({ ...initialVariant })
      }
      if (searchPharm) {
        const decodedSearch = decodeURIComponent(
          searchPharm.replace(/ - /g, "-"),
        ).trim()
        function sanitizeString(input) {
          let sanitized = ""
          for (let i = 0; i < input.length; i++) {
            const char = input[i]
            if (
              (char >= "a" && char <= "z") ||
              (char >= "A" && char <= "Z") ||
              (char >= "0" && char <= "9") ||
              char === "-"
            ) {
              sanitized += char
            }
          }
          return sanitized
        }
        const sanitizedDecodedSearch =
          sanitizeString(decodedSearch).toLowerCase()
        const selectedSearch = variants.find(
          item =>
            sanitizeString(item.title.toLowerCase()) === sanitizedDecodedSearch,
        )
        if (selectedSearch) {
          setVariant({ ...selectedSearch })
          handleOptionChange1(selectedSearch)
        } else {
          setVariant({ ...initialVariant })
        }
      }
    }
  }, [location, isInitialRender])

  const [apiData, setAPIData] = useState([])

  useEffect(() => {
    if (variant?.shopifyId && apiData?.length) {
      const currentVariant = apiData?.filter(
        v => v?.shopifyId === variant?.shopifyId,
      )?.[0]
      if (currentVariant) {
        const available = currentVariant?.availableForSale
        const preorder = currentVariant?.metafields?.filter(m =>
          m?.key.includes("preorder-date"),
        )?.[0]?.value
        const preOrderField = {
          preorder:
            currentVariant?.availableForSale &&
            currentVariant?.inventory_quantity <= 0,
          preorderDate: preorder,
        }
        setAPISelectedVariant({
          available,
          preorder: preOrderField,
          inventory_quantity: currentVariant?.inventory_quantity,
        })
      } else {
        setAPISelectedVariant(null)
      }
    }
  }, [variant, apiData])
  const handleOptionChange1 = value => {
    const selectedVariant = value
    const removeValueAfterPng = value => {
      const index = value.indexOf(".png")
      return index !== -1 ? value.substring(0, index + 4) : value
    }
    if (selectedVariant?.image) {
      const fieldToChoose =
        selectedVariant?.image?.src ||
        selectedVariant?.image?.altText ||
        selectedVariant?.image?.originalSrc ||
        selectedVariant?.image?.id ||
        selectedVariant?.image?.src ||
        ""
      const fieldWithoutExtension = removeValueAfterPng(fieldToChoose)
      const selectedShopifyImageId = fieldWithoutExtension || undefined
      if (selectedShopifyImageId) {
        if (imageEnumeration[fieldToChoose]) {
          setImageIndex(imageEnumeration[fieldToChoose])
        } else if (
          imageEnumeration[
            removeValueAfterPng(fieldToChoose.split(".webp")?.[0])
          ] !== undefined
        ) {
          setImageIndex(
            imageEnumeration[
              removeValueAfterPng(fieldToChoose.split(".webp")?.[0])
            ],
          )
        } else {
          for (const key of Object.keys(imageEnumeration)) {
            const keyWithoutExtension = removeValueAfterPng(key)
            const searchStringWithoutExtension = removeValueAfterPng(
              fieldWithoutExtension,
            )
            if (keyWithoutExtension === searchStringWithoutExtension) {
              const value = imageEnumeration[key]
              setImageIndex(value)
              return value
              break
            }
          }
        }
      }
    }

    if (value?.title && optionsLibrary.root) {
      const beautifulTitle = value?.title?.split?.(" / ")?.[0]
      if (optionsLibrary.root.includes(beautifulTitle)) {
        setAvailableSubOptions(optionsLibrary[beautifulTitle])
      }
    }

    setRunVariantFilter(true)

    setVariant({ ...selectedVariant })
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div>
        <KlaviyoScript />
        <WidgetScript />
        <RetentionViewProduct
          productId={productId}
          product={product}
          location={location}
          orderId={orderId}
          totalPrice={totalPrice}
          email={email}
        />
        {images.length ? (
          <Seo
            title={title}
            description={description}
            ogImage={getSrc(images[0].image.gatsbyImageData)}
          />
        ) : (
          <Seo title={title} description={description} />
        )}
        <div className="sticky top-0 z-[99999] ">{stickyNavComponent}</div>

        <div
          className="w-full min-h-screen flex justify-between items-start"
          ref={productRef}
          onScroll={handleChild1Scroll}
        >
          <div className="w-full overflow-x-hidden  ">
            <div className="w-full  lg:px-10 xl:px-28 py-4 lg:py-7 relative">
              <div className="flex ml-[15px] xl:ml-[0px] text-xs md:text-sm items-center justify-start flex-row lg:mr-0">
                <Link
                  to="/"
                  className="hover:underline hover:pointer mr-2 mt-1 text-obsidian text-xs md:text-sm font-medium font-sans"
                >
                  Home
                </Link>
                {collections?.length ? (
                  <div className="mr-2 text-center mt-1.5">|</div>
                ) : (
                  ""
                )}

                {collections?.length &&
                collections?.[collectionIndex]?.handle ? (
                  <Link
                    to={`/collections/${collections?.[collectionIndex].handle}`}
                    className="hover:underline text-xs hover:pointer mr-2 mt-1 text-obsidian text-xs md:text-sm font-medium font-sans"
                  >
                    {collections?.[collectionIndex]?.title}
                  </Link>
                ) : (
                  ""
                )}
                {collections?.length ? (
                  <div className="mr-2 text-center mt-1.5">|</div>
                ) : (
                  ""
                )}

                <p className="mr-2 mt-1 mb-0 text-obsidian text-xs font-regular font-sans">
                  {title}
                </p>
              </div>
              <div className="mt-6">
                <div className="px-[20px]">
                  <FeaturedProductImagesCarousel
                    images={images}
                    title={title}
                    imageIndex={imageIndex}
                    variant={variant}
                    runVariantFilter={runVariantFilter}
                  />
                </div>
                <div className="lg:hidden bg-black">
                  <AddToCartSidebar
                    onScroll={handleChild2Scroll}
                    title={title}
                    description={description}
                    sku={variantSKU}
                    product={product}
                    productShopifyId={productShopifyId}
                    price={price}
                    lineItem={lineItem}
                    setLineItem={setLineItem}
                    preorder={apiSelectedVariant?.preorder || preorder}
                    available={
                      apiSelectedVariant?.available !== undefined
                        ? apiSelectedVariant?.available
                        : available
                    }
                    variant={variant}
                    categoryName={categoryName}
                    notice={notice}
                    hasVariants={hasVariants}
                    options={options}
                    availableSubOptions={availableSubOptions}
                    optionsLibrary={optionsLibrary}
                    handleOptionChange={handleOptionChange}
                    setQuantity={setQuantity}
                    showAlmostOutMessage={
                      apiSelectedVariant?.inventory_quantity !== undefined
                        ? apiSelectedVariant?.inventory_quantity < 25 &&
                          apiSelectedVariant?.inventory_quantity > 0
                        : variant?.inventoryQuantity < 25 &&
                          variant?.inventoryQuantity > 0
                    }
                    quantity={quantity}
                    metafields={metafields}
                    storefrontId={storefrontId}
                    id={id}
                    variants={variants}
                    handleClick={handleClick}
                    single={variants[0]?.availableForSale}
                    popupContent={popupContent}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    // selectedVariantPrice={selectedVariantPrice}
                    // countryCurrency={countryCurrency}
                    // currencyCompareAt={currencyCompareAt}
                    affirmVariantPrice={affirmVariantPrice}
                  />

                  {showDiv && (
                    <div
                      style={{ zIndex: cartOverlay ? "999999" : "50" }}
                      className="lg:hidden bottom-0 fixed duration-300 ease-in-out transform w-full z-50"
                    >
                      <MobileScrollAddCart
                        title={title}
                        description={description}
                        sku={variantSKU}
                        product={product}
                        productShopifyId={productShopifyId}
                        price={price}
                        lineItem={lineItem}
                        setLineItem={setLineItem}
                        preorder={apiSelectedVariant?.preorder || preorder}
                        available={
                          apiSelectedVariant?.available !== undefined
                            ? apiSelectedVariant.available
                            : available
                        }
                        variant={variant}
                        categoryName={categoryName}
                        notice={notice}
                        hasVariants={hasVariants}
                        options={options}
                        availableSubOptions={availableSubOptions}
                        optionsLibrary={optionsLibrary}
                        handleOptionChange={handleOptionChange}
                        setQuantity={setQuantity}
                        quantity={quantity}
                        metafields={metafields}
                        storefrontId={storefrontId}
                        id={id}
                        variants={variants}
                        handleClick={handleClick}
                        single={variants[0]?.availableForSale}
                        popupContent={popupContent}
                        // selectedVariantPrice={selectedVariantPrice}
                        // countryCurrency={countryCurrency}
                        // currencyCompareAt={currencyCompareAt}
                        affirmVariantPrice={affirmVariantPrice}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {features && overview ? (
              <div className="mx-auto pt-[13px] md:pt-[20px] relative z-20 px-[20px] md:px-0 flex flex-col md:flex-row justify-center gap-[30px] md:gap-[112px]">
                <div className="w-full md:w-[40%] lg:w-[35%] feature-overview-divider">
                  <p className="font-sans font-black text-[30px]">Overview</p>
                  <div className="text-base text-[15px] leading-[22px]">
                    {overview}
                  </div>
                </div>
                <div className="w-full md:w-[40%] lg:w-[35%]">
                  <p className="font-sans font-black text-[30px]">Features</p>

                  <div className=" list_styling text-base text-[15px] leading-[18px]">
                    {features}
                  </div>
                </div>
              </div>
            ) : (
              <div className=" px-[20px] sm:px-[60px] lg:px-[110px] ">
                <div
                  className=""
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                />
              </div>
            )}
            {!!story?.content?.cases_and_components?.length &&
              !!story?.content?.user_guide?.length && (
                <div className="border-y-[#eseses] border-t border-solid border-b flex flex-col h-auto sm:h-[120px] gap-6 sm:flex-row mt-8 justify-center text-center px:4 sm:px-0 bg-gray-100 items-center gap-0 sm:gap-[80px] md:gap-[100px] lg:gap-[150px] text-gray-500">
                  <Link
                    to={story?.content?.cases_and_components[0]?.link}
                    className="border-b sm:border-b-0 hover:border-b w-full sm:w-fit"
                  >
                    <p className="m-0 my-10 sm:my-0 text-md md:text-md lg:text-xl cursor-pointer hover:text-black hover:border-b-[1px] hover:border-black font-black font-sans inline-block xs:block">
                      {story?.content?.cases_and_components[0]?.title}
                    </p>
                  </Link>

                  <Link to={story?.content?.user_guide[0]?.link}>
                    <p className="m-0 my-10 sm:my-0 text-md md:text-md lg:text-xl cursor-pointer hover:text-black hover:border-b-[1px] hover:border-black font-black font-sans">
                      {story?.content?.user_guide[0]?.title}
                    </p>
                  </Link>
                </div>
              )}
            {array?.length ? <DynamicComponentsV2 components={array} /> : null}

            {/* {isCustomOverview &&
          <div>
            <GenericTemplateBottom title={product.title} metafields={product.metafields} />
          </div>
          } */}
            {order !== undefined && (
              <div>
                {order === "1" ? (
                  <div>
                    <div className="below-content video-bg-custom-apex">
                      {sections?.length && displayBottom === true ? (
                        <GenericTemplateBottom
                          title={product.title}
                          metafields={product.metafields}
                        />
                      ) : (
                        ""
                      )}

                      <ProductBottomSection
                        sections={sections}
                        BottomSectionImage1={BottomSectionImage1}
                        BottomSectionImage2={BottomSectionImage2}
                      />
                    </div>
                    <div className="">
                      <DynamicComparison
                        allSliders={allSliders}
                        comparisonMainImage={comparisonMainImage}
                        explainerVideobanner={explainerVideobanner}
                        multipleSectionTitle={multipleSectionTitle}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="">
                      <DynamicComparison
                        allSliders={allSliders}
                        comparisonMainImage={comparisonMainImage}
                        explainerVideobanner={explainerVideobanner}
                        multipleSectionTitle={multipleSectionTitle}
                      />
                    </div>
                    <div className="below-content video-bg-custom-apex">
                      {sections?.length && displayBottom === true ? (
                        <GenericTemplateBottom
                          title={product.title}
                          metafields={product.metafields}
                        />
                      ) : (
                        ""
                      )}

                      {sections?.length ? (
                        <ProductBottomSection
                          sections={sections}
                          BottomSectionImage1={BottomSectionImage1}
                          BottomSectionImage2={BottomSectionImage2}
                        />
                      ) : (
                        ""
                      )}
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {order === undefined && (
              <div className="below-content video-bg-custom-apex">
                {sections?.length && displayBottom === true ? (
                  <GenericTemplateBottom
                    title={product.title}
                    metafields={product.metafields}
                  />
                ) : (
                  ""
                )}

                {sections?.length ? (
                  <ProductBottomSection
                    sections={sections}
                    BottomSectionImage1={BottomSectionImage1}
                    BottomSectionImage2={BottomSectionImage2}
                  />
                ) : (
                  ""
                )}
                <div></div>
              </div>
            )}

            {isCustomOverview ? belowContentComponent : null}
            {/*  Dynamic comparison  */}

            {/* Complete Your Setup */}
            <div className="border-t mt-4 px-[75px] mt-10 pt-10 max-w-screen-xl m-auto ">
              <p className="py-3 px-10 text-center font-black bg-white text-[22px] toggleActive1">
                Complete Your Setup
              </p>
              {recommendedProducts.length < 4 ? (
                <div className="ProductRecommendation relative max-w-screen-xl m-auto mx-7 3xl:mx-0 mt-10">
                  <ProductRecommendationv2
                    product={product}
                    recommendedProducts={recommendedProducts}
                    productShopifyId={productShopifyId}
                  />
                </div>
              ) : (
                <div className="recommendedProdutsContainer w-full md:px-[75px] block relative max-w-[1280px] m-auto">
                  <div className="absolute leftArrow">
                    <button
                      onClick={() => carouselRef.current.previous()}
                      className="w-[42px] h-[42px] bg-[gray] hover:bg-[#C99E1A] rounded-full relative"
                    ></button>
                  </div>
                  <div className="absolute rightArrow">
                    <button
                      onClick={() => carouselRef.current.next()}
                      className="w-[42px] h-[42px] bg-[gray] hover:bg-[#C99E1A] rounded-full relative"
                    ></button>
                  </div>
                  <Carousel
                    responsive={responsive}
                    swipeable
                    ref={carouselRef}
                    arrows={false}
                    draggable
                    showDots
                    infinite
                    renderDotsOutside
                    containerClass={`${style.sixConfig} sixConfigArrow reconCustomSlider productRecommendationCustomSlider`}
                    dotListClass={`${style.reconCustomDotSlider} ${style.sixConfig} reconCustomDotSlider`}
                  >
                    {recommendedProducts.map(recommendedProduct => {
                      if (recommendedProduct) {
                        return (
                          <a href={`/products/${recommendedProduct.handle}`}>
                            <div className="w-[90%]">
                              <img
                                src={
                                  recommendedProduct.featuredImage.originalSrc
                                }
                                alt={recommendedProduct.title}
                              />

                              <p className="font-bold text-[14px] pointer-events-none">
                                {recommendedProduct.title}
                              </p>
                              <div>
                                {recommendedProduct.metafields
                                  .filter(
                                    metafield =>
                                      metafield.key === "quickview-content",
                                  )
                                  .map(metafield => (
                                    <p
                                      className="text-[12px] leading-normal pointer-events-none w-[90%] h-[60px]"
                                      dangerouslySetInnerHTML={{
                                        __html: metafield.value,
                                      }}
                                    />
                                  ))}
                                <p className="font-bold text-[14px] pointer-events-none">
                                  From $
                                  {parseFloat(
                                    recommendedProduct.priceRangeV2
                                      .minVariantPrice.amount,
                                  ).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </a>
                        )
                      }
                      return null
                    })}
                  </Carousel>
                </div>
              )}
            </div>
            {/* Ratings and Commets */}
            <div ref={myDivRef} id="ratingSection" className="mx-7 mt-10 ">
              <div className="max-w-screen-xl m-auto mt-2 pt-10 border-t">
                <RatingsAndComments
                  product={product}
                  productShopifyId={productShopifyId}
                  reviewData={reviewsData}
                  product_id={productId}
                  questionsData={questionsData}
                />
              </div>
            </div>
          </div>
          <div
            className="sticky top-0 right-0 hidden lg:block z-[999]"
            onMouseEnter={() => setMouseRightHover(true)}
            onMouseLeave={() => setMouseRightHover(false)}
          >
            <AddToCartSidebar
              ref={cartRef}
              onScroll={handleChild2Scroll}
              title={title}
              description={description}
              sku={variantSKU}
              product={product}
              productShopifyId={productShopifyId}
              price={price}
              lineItem={lineItem}
              setLineItem={setLineItem}
              preorder={apiSelectedVariant?.preorder || preorder}
              available={
                apiSelectedVariant?.available !== undefined
                  ? apiSelectedVariant?.available
                  : available
              }
              showAlmostOutMessage={
                apiSelectedVariant?.inventory_quantity !== undefined
                  ? apiSelectedVariant?.inventory_quantity < 25 &&
                    apiSelectedVariant?.inventory_quantity > 0
                  : variant?.inventoryQuantity < 25 &&
                    variant?.inventoryQuantity > 0
              }
              variant={variant}
              categoryName={categoryName}
              notice={notice}
              hasVariants={hasVariants}
              options={options}
              availableSubOptions={availableSubOptions}
              optionsLibrary={optionsLibrary}
              handleOptionChange={handleOptionChange}
              setQuantity={setQuantity}
              quantity={quantity}
              metafields={metafields}
              storefrontId={storefrontId}
              id={id}
              variants={variants}
              affirmVariantPrice={affirmVariantPrice}
              handleClick={handleClick}
              single={variants[0]?.availableForSale}
              popupContent={popupContent}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              // selectedVariantPrice={selectedVariantPrice}
              // countryCurrency={countryCurrency}
              // currencyCompareAt={currencyCompareAt}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DefaultGenericProductv2
