import React, { useState } from "react"
import axios from "axios"
import * as style from "./iphone.module.scss"

const Iphone16Case = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isConflicted, setIsConflicted] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const handleSubmit = async e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
    setIsConflicted(false)
    setIsInvalid(false)
    try {
      await axios
        .post(
          "/.netlify/functions/iphone16-add-member",
          {
            email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Klaviyo-API-Key pk_94150677c3529718aa5a9ca944f26fe945",
              revision: "2024-07-15",
            },
          },
        )
        .then(resp => {
          setError(false)
          setSuccess(true)
        })
        .catch(err => {
          console.log(err)
          const statusCode = err?.response?.status
          const hasConflict = statusCode === 409
          const isInvalidCode = statusCode === 400
          setError(true)
          setSuccess(false)
          if (hasConflict) {
            setIsConflicted(true)
          }
          if (isInvalidCode) {
            setIsInvalid(true)
          }
        })
    } catch (error) {
      setError(true)
      setSuccess(false)
    }
  }

  return (
    <>
      <div className={`${style.klaviyoForm} w-full text-center`}>
        <a href="/" className={`${style.linkToIndex}`}>
          &nbsp;
        </a>
        <div className={`${style.dataForm}`}>
          <p className="text-white text-base md:text-2xl m-0">
            Dropping In November
          </p>
          <p className="text-white text-xl md:text-2xl lg:text-4xl font-extrabold mb-2">
            GET EARLY ACCESS
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex w-full gap-3 flex-col justify-center items-center"
          >
            {!success && (
              <div className="flex justify-center w-full space-x-3">
                <input
                  id="email_122923535"
                  className="h-7 sm:h-8 pl-2 w-[95%] hover:border hover:border-black placeholder:text-sm"
                  type="email"
                  autoComplete="email"
                  name="email"
                  placeholder="Enter your email"
                  aria-invalid="true"
                  onChange={e => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="hover:bg-paloverde bg-white font-semibold py-1 sm:py-1.5 px-3 text-xs hover:text-white cursor-pointer rounded-sm border-l"
                >
                  JOIN
                </button>
              </div>
            )}
            <div className="px-4">
              {error ? (
                <p className="font-sans italic text-white text-center text-sm transition-all duration-[250ms] bg-red-600 p-2">
                  {isConflicted
                    ? "You're already in the crew!"
                    : isInvalid
                      ? "Please enter a valid email address"
                      : "Something went wrong, please try again later"}
                </p>
              ) : null}
              {success ? (
                <p className="font-sans italic text-white text-center text-sm transition-all duration-[250ms] p-4">
                  You&apos;re in the crew!
                  <br />
                  Stay tuned for early access.
                </p>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Iphone16Case
