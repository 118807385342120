import React from "react"

function ImageComponent(props: any) {
  const isVideo = (url: string) => {
    const videoExtensions = ["mp4", "webm", "ogg"]
    const extension = url.split(".").pop()
    return videoExtensions.includes(extension || "")
  }

  const selectedURL = props?.image?.filename || props?.Image?.filename
  const selectedMobileUrl =
    props?.mobileImage?.filename || props?.mobileImage?.filename
  const selectedAlt =
    props?.alt || props?.Alt || props?.image?.alt || props?.Image?.alt

  return (
    <div className="flex w-full justify-center">
      {props?.mediaUrl?.url?.length || props?.mobileMediaUrl?.url?.length ? (
        isVideo(props?.mediaUrl?.url) ? (
          <>
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              className="w-full !mb-0 hidden md:block"
              src={props?.mediaUrl?.url}
            >
              <source src={props.mediaUrl?.url} />
            </video>
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              src={props?.mobileMediaUrl?.url}
              className="w-full !mb-0 block md:hidden"
            >
              <source src={selectedMobileUrl} type="video/mp4" />
            </video>
          </>
        ) : (
          <>
            <img
              className="w-full !mb-0 hidden md:block bg-black"
              src={props?.mediaUrl?.url}
              alt="mediaContent"
            />
            <img
              className="w-full !mb-0 block md:hidden"
              src={props?.mobileMediaUrl?.url}
              alt="mediaContent"
            />
          </>
        )
      ) : selectedURL ? (
        isVideo(selectedURL) ? (
          <>
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              src={selectedURL}
              className="w-full !mb-0 hidden md:block"
            >
              <source src={selectedURL} type="video/mp4" />
            </video>
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              src={selectedMobileUrl}
              className="w-full !mb-0 block md:hidden"
            >
              <source src={selectedMobileUrl} type="video/mp4" />
            </video>
          </>
        ) : (
          <>
            <img
              className="w-full m-0 hidden lg:block"
              src={selectedURL}
              alt="mediaContent"
            />
            <img
              className="w-full m-0 block lg:hidden"
              src={selectedMobileUrl}
              alt="mediaContent"
            />
          </>
        )
      ) : null}
    </div>
  )
}

export default ImageComponent
